<template>
  <div class="home">
    <div class="dati_01">
      温馨提示:
      根据中国证监会、中国基金业协会监管要求，私募基金应当向合格投资者募集，特设置本调查问卷，请您如实填写，感谢您的支持与配合。
    </div>
    <div class="dati_02"><b>个人版</b><span>Personal edition</span></div>
    <div class="dati_03">
      <!---->
      <div class="dato_03_titxn">
        <div class="dato_03_tit1xn">
          <div style="padding-bottom: 5px">风险提示:</div>
          私募基金投资需承担各类风险，本金可能遭受损失。同时，私募基金投资还要考虑市场风险、信用风险、流动性风险、操作风险等各类投资风险。您在基金认购过程中应当注意核对自己的风险识别和风险承受能力，选择与自己风险识别能力和风险承受能力相匹配的私募基金。
        </div>
      </div>

      <div class="dati_031">
        <div class="dati_0311">请承诺您是为自己购买私募基金产品</div>
        <div class="dati_031-list">
          <ul>
            <li>
              <input
                type="radio"
                name="wenti88"
                value="1"
                @click="answer"
              />&nbsp;是
            </li>
            <li>
              <input
                type="radio"
                name="wenti88"
                value="3"
                @click="answer"
              />&nbsp;否
            </li>
            <div class="clearfix"></div>
          </ul>
        </div>
      </div>
      <div class="dati_031">
        <div class="dati_0311">
          请选择您符合以下【
          】的合格投资者财务条件（如不符合，则不具备购买私募基金的法定资格）。
        </div>
        <div class="dati_031-list">
          <ul>
            <li>
              <input type="radio" name="wenti99" value="A" />&nbsp;A
              符合金融资产不低于300万元（金融资产包括银行存款、股票、债券、基金份额、资产管理计划、银行理财产品、信托计划、保险产品、期货权益等）；
            </li>
            <li>
              <input type="radio" name="wenti99" value="B" />&nbsp;B
              符合最近三年个人年均收入不低于50万元。
            </li>
            <div class="clearfix"></div>
          </ul>
        </div>
      </div>
      <!---->

      <div class="dato_03_tit"><div class="dato_03_tit1">基本信息</div></div>
      <div class="dati_031">
        <div class="dati_0311">1.您的年龄介于</div>
        <div class="dati_031-list">
          <ul>
            <li>
              <input type="radio" name="wenti1" value="1" />&nbsp;高于65岁
            </li>
            <li><input type="radio" name="wenti1" value="3" />&nbsp;18-30岁</li>
            <li><input type="radio" name="wenti1" value="5" />&nbsp;31-50岁</li>
            <li><input type="radio" name="wenti1" value="7" />&nbsp;51-65岁</li>
            <div class="clearfix"></div>
          </ul>
        </div>
      </div>

      <div class="dati_031">
        <div class="dati_0311">2.你的学历</div>
        <div class="dati_031-list">
          <ul>
            <li>
              <input type="radio" name="wenti2" value="1" />&nbsp;高中及以下
            </li>
            <li>
              <input type="radio" name="wenti2" value="3" />&nbsp;中专或大专
            </li>
            <li><input type="radio" name="wenti2" value="5" />&nbsp;本科</li>
            <li>
              <input type="radio" name="wenti2" value="7" />&nbsp;硕士及以上
            </li>
            <div class="clearfix"></div>
          </ul>
        </div>
      </div>

      <div class="dati_031">
        <div class="dati_0311">3.您的职业为</div>
        <div class="dati_031-list">
          <ul>
            <li>
              <input type="radio" name="wenti3" value="1" />&nbsp;无固定职业
            </li>
            <li>
              <input
                type="radio"
                name="wenti3"
                value="3"
              />&nbsp;一般企事业单位员工
            </li>
            <li>
              <input type="radio" name="wenti3" value="5" />&nbsp;专业技术人员
            </li>
            <li>
              <input
                type="radio"
                name="wenti3"
                value="7"
              />&nbsp;金融行业从业人员、企业主、高层管理人员
            </li>
            <div class="clearfix"></div>
          </ul>
        </div>
      </div>

      <div class="dato_03_tit"><div class="dato_03_tit1">财务状况</div></div>

      <div class="dati_031">
        <div class="dati_0311">4.您的家庭可支配年收入为（折合人民币）？</div>
        <div class="dati_031-list">
          <ul>
            <li>
              <input type="radio" name="wenti4" value="1" />&nbsp;50万元以下
            </li>
            <li>
              <input type="radio" name="wenti4" value="3" />&nbsp;50—100万元
            </li>
            <li>
              <input type="radio" name="wenti4" value="5" />&nbsp;100—500万元
            </li>
            <li>
              <input type="radio" name="wenti4" value="7" />&nbsp;500—1000万元
            </li>
            <li>
              <input type="radio" name="wenti4" value="8" />&nbsp;1000万元以上
            </li>
            <div class="clearfix"></div>
          </ul>
        </div>
      </div>

      <div class="dati_031">
        <div class="dati_0311">
          5.在您每年的家庭可支配收入中，可用于金融投资（储蓄存款除外）的比例为？
        </div>
        <div class="dati_031-list">
          <ul>
            <li><input type="radio" name="wenti5" value="1" />&nbsp;小于10%</li>
            <li>
              <input type="radio" name="wenti5" value="3" />&nbsp;10%至25%
            </li>
            <li>
              <input type="radio" name="wenti5" value="5" />&nbsp;25%至50%
            </li>
            <li><input type="radio" name="wenti5" value="7" />&nbsp;大于50%</li>
            <div class="clearfix"></div>
          </ul>
        </div>
      </div>

      <div class="dato_03_tit"><div class="dato_03_tit1">投资知识</div></div>

      <div class="dati_031">
        <div class="dati_0311">6.您的投资知识可描述为</div>
        <div class="dati_031-list-lie">
          <ul>
            <li>
              <input
                type="radio"
                name="wenti6"
                value="1"
              />&nbsp;没有任何投资知识
            </li>
            <li>
              <input
                type="radio"
                name="wenti6"
                value="3"
              />&nbsp;有限：基本没有金融产品方面的知识
            </li>
            <li>
              <input
                type="radio"
                name="wenti6"
                value="5"
              />&nbsp;一般：对金融产品及其相关风险具有基本的知识和理解
            </li>
            <li>
              <input
                type="radio"
                name="wenti6"
                value="7"
              />&nbsp;丰富：对金融产品及其相关风险具有丰富的知识和理解
            </li>
            <div class="clearfix"></div>
          </ul>
        </div>
      </div>

      <div class="dati_031">
        <div class="dati_0311">7.您的投资经验可描述为</div>
        <div class="dati_031-list-lie">
          <ul>
            <li>
              <input
                type="radio"
                name="wenti7"
                value="1"
              />&nbsp;除银行储蓄外，基本没有其他投资经验
            </li>
            <li>
              <input
                type="radio"
                name="wenti7"
                value="3"
              />&nbsp;购买过债券、保险等理财产品
            </li>
            <li>
              <input
                type="radio"
                name="wenti7"
                value="5"
              />&nbsp;参与过股票、基金等产品的交易
            </li>
            <li>
              <input
                type="radio"
                name="wenti7"
                value="7"
              />&nbsp;参与过权证、期货、期权等产品的交易
            </li>
            <div class="clearfix"></div>
          </ul>
        </div>
      </div>

      <div class="dati_031">
        <div class="dati_0311">
          8.您有多少年投资基金、股票、信托、私募证券或金融衍生产品等风险投资品的经验？
        </div>
        <div class="dati_031-list">
          <ul>
            <li>
              <input type="radio" name="wenti8" value="1" />&nbsp;没有经验
            </li>
            <li><input type="radio" name="wenti8" value="3" />&nbsp;少于2年</li>
            <li><input type="radio" name="wenti8" value="5" />&nbsp;2至5年</li>
            <li><input type="radio" name="wenti8" value="7" />&nbsp;5至10年</li>
            <li>
              <input type="radio" name="wenti8" value="8" />&nbsp;10年以上
            </li>
            <div class="clearfix"></div>
          </ul>
        </div>
      </div>

      <div class="dato_03_tit"><div class="dato_03_tit1">投资目标</div></div>

      <div class="dati_031">
        <div class="dati_0311">9.您计划的投资期限是多久？</div>
        <div class="dati_031-list">
          <ul>
            <li><input type="radio" name="wenti9" value="1" />&nbsp;1年以下</li>
            <li><input type="radio" name="wenti9" value="3" />&nbsp;1至3年</li>
            <li><input type="radio" name="wenti9" value="5" />&nbsp;3至5年</li>
            <li><input type="radio" name="wenti9" value="7" />&nbsp;5年以上</li>
            <div class="clearfix"></div>
          </ul>
        </div>
      </div>

      <div class="dati_031">
        <div class="dati_0311">10.您的投资目的是？</div>
        <div class="dati_031-list">
          <ul>
            <li>
              <input type="radio" name="wenti10" value="1" />&nbsp;获取固定收益
            </li>
            <li>
              <input type="radio" name="wenti10" value="3" />&nbsp;资产保值
            </li>
            <li>
              <input
                type="radio"
                name="wenti10"
                value="5"
              />&nbsp;资产中长期稳健增长
            </li>
            <li>
              <input
                type="radio"
                name="wenti10"
                value="7"
              />&nbsp;资产短期迅速增长
            </li>
            <div class="clearfix"></div>
          </ul>
        </div>
      </div>

      <div class="dato_03_tit"><div class="dato_03_tit1">风险偏好</div></div>

      <div class="dati_031">
        <div class="dati_0311">11.以下哪项描述最符合您的投资态度？</div>
        <div class="dati_031-list-lie">
          <ul>
            <li>
              <input
                type="radio"
                name="wenti11"
                value="1"
              />&nbsp;厌恶风险，不希望本金损失，希望获得稳定回报
            </li>
            <li>
              <input
                type="radio"
                name="wenti11"
                value="3"
              />&nbsp;保守投资，不希望本金损失，愿意承担一定幅度的收益波动
            </li>
            <li>
              <input
                type="radio"
                name="wenti11"
                value="5"
              />&nbsp;寻求资金的较高收益和成长性，愿意为此承担有限本金损失
            </li>
            <li>
              <input
                type="radio"
                name="wenti11"
                value="7"
              />&nbsp;希望赚取高回报，愿意为此承担较大本金损失
            </li>
            <div class="clearfix"></div>
          </ul>
        </div>
      </div>

      <div class="dati_031">
        <div class="dati_0311">
          12.假设有两种投资：投资A预期获得10%的收益，可能承担的损失非常小；投资B预期获得30%的收益，但可能承担较大亏损。您会怎么支配您的投资
        </div>
        <div class="dati_031-list-lie">
          <ul>
            <li>
              <input
                type="radio"
                name="wenti12"
                value="1"
              />&nbsp;全部投资于收益较小且风险较小的A
            </li>
            <li>
              <input
                type="radio"
                name="wenti12"
                value="3"
              />&nbsp;同时投资于A和B，但大部分资金投资于收益较小且风险较小的A
            </li>
            <li>
              <input
                type="radio"
                name="wenti12"
                value="5"
              />&nbsp;同时投资于A和B，但大部分资金投资于收益较大且风险较大的B
            </li>
            <li>
              <input
                type="radio"
                name="wenti12"
                value="7"
              />&nbsp;全部投资于收益较大且风险较大的B
            </li>
            <div class="clearfix"></div>
          </ul>
        </div>
      </div>

      <div class="dati_031">
        <div class="dati_0311">13.您的投资目的是？</div>
        <div class="dati_031-list">
          <ul>
            <li>
              <input type="radio" name="wenti13" value="1" />&nbsp;10%以内
            </li>
            <li>
              <input type="radio" name="wenti13" value="3" />&nbsp;10%-30%
            </li>
            <li>
              <input type="radio" name="wenti13" value="5" />&nbsp;30%-50%
            </li>
            <li>
              <input type="radio" name="wenti13" value="7" />&nbsp;超过50%
            </li>
            <div class="clearfix"></div>
          </ul>
        </div>
      </div>

      <div class="dati_031">
        <div class="dati_0311">14.您认为自己能承受的最大投资损失是多少？</div>
        <div class="dati_031-list">
          <ul>
            <li>
              <input type="radio" name="wenti14" value="1" />&nbsp;10%以内
            </li>
            <li>
              <input type="radio" name="wenti14" value="3" />&nbsp;10%-30%
            </li>
            <li>
              <input type="radio" name="wenti14" value="5" />&nbsp;30%-50%
            </li>
            <li>
              <input type="radio" name="wenti14" value="7" />&nbsp;超过50%
            </li>
            <div class="clearfix"></div>
          </ul>
        </div>
      </div>

      <div class="dati_04">
        <div class="dati_041">
          <div>
            <span style="color: #ea5505; font-size: 18px">注：</span
            ><br />1．投资者应确认在进行问卷调查时，所填写的选项表达真实。<br />
            2．调查问卷为指导公司了解投资者风险认知水平和风险承受能力提供参考。本次调查在任何方面均不构成对投资者的投资建议，或对投资者的投资决策形成
            实质影响。若投资者根据调查结果进行投资，完全属于投资者的独立行为，相应的风险亦由投资者独立承担。
          </div>
          <el-row>
            <el-col
              :span="6"
              :offset="9"
              class="btn"
              v-if="$width > 767"
              @click.native="setResultScore"
              >提 交</el-col
            >
            <el-col
              :span="16"
              :offset="4"
              class="btn"
              v-if="$width < 767"
              @click.native="setResultScore"
              >提 交</el-col
            >
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  name: "Home",
  data() {
    return {
      fenshu: [
        [1, 3, 5, 7],
        [1, 3, 5, 7],
        [1, 3, 5, 7],
        [1, 3, 5, 7, 8],
        [1, 3, 5, 7],
        [1, 3, 5, 7],
        [1, 3, 5, 7],
        [1, 3, 5, 7, 8],
        [1, 3, 5, 7],
        [1, 3, 5, 7],
        [1, 3, 5, 7],
        [1, 3, 5, 7],
        [1, 3, 5, 7],
        [1, 3, 5, 7],
      ],
      answerList: {
        wenti88: null,
        wenti99: null,
        wenti1: null,
        wenti2: null,
        wenti3: null,
        wenti4: null,
        wenti5: null,
        wenti6: null,
        wenti7: null,
        wenti8: null,
        wenti9: null,
        wenti10: null,
        wenti11: null,
        wenti12: null,
        wenti13: null,
        wenti14: null,
      },
      defen: 0,
    };
  },
  methods: {
    answer(e) {
      this.answerList[e.target.name] = e.target.value;
    },
    setResultScore() {
      api
        .setResultScore({
          dc: 1,
          dt: 2,
          ddata: new Date().toISOString(),
        })
        .then((res) => {
          console.log(res);
        });
    },
  },
  components: {},
};
</script>
<style scoped>
.home {
  color: #404040;
}
.dati_01 {
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
  padding: 30px 0px;
}
.dati_02 {
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
  line-height: 80px;
  height: 80px;
  border: 2px solid #c4c4c4;
  border-left: 0px;
  border-right: 0px;
}
.dati_02 b {
  font-size: 36px;
  color: #ea5504;
  font-weight: 100;
  padding-right: 30px;
}
.dati_02 span {
  border-left: 1px solid #999999;
  padding-left: 20px;
  font-size: 20px;
  text-transform: Uppercase;
  color: #999999;
}
.dati_03 {
  width: 100%;
  margin-top: 30px;
}
.dato_03_titxn {
  width: 100%;
  height: auto;
  line-height: 25px;
  background: #f1f1f1;
  color: #333;
  padding: 10px 0px;
}
.dato_03_tit1xn {
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
  font-size: 16px;
}
.dati_031 {
  width: 100%;
  max-width: 1200px;
  margin: 20px auto 0px auto;
  border-bottom: 2px solid #f1f1f1;
  padding: 10px 0px;
}
.dati_0311 {
  font-size: 16px;
}
.dati_031-list {
  width: 100%;
  padding: 20px 0px;
  color: #404040;
}
ul,
li,
ol {
  margin: 0;
  padding: 0;
  list-style: none outside none;
}
.dati_031-list ul li {
  display: inline-block;
  padding-right: 70px;
  font-size: 14px;
  line-height: 40px;
}
.dato_03_tit {
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #f1f1f1;
  color: #000000;
}
.dato_03_tit1 {
  width: 100%;
  max-width: 1200px;
  margin: 0px auto;
  font-size: 18px;
}
.dati_04 {
  width: 100%;
  height: auto;
  padding: 40px 0px;
  line-height: 30px;
  background: #f1f1f1;
  color: #000000;
}
.dati_041 {
  width: 100%;
  max-width: 1200px;
  margin: 0px auto 0px auto;
}
.btn {
  margin-top: 30px;
  background: #ec6526;
  color: #fff;
  padding: 15px 100px;
  font-size: 24px;
  text-align: center;
  cursor: pointer;
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .dati_01,
  .dati_02,
  .dati_03 {
    width: 96%;
  }
  .dati_03 {
    margin: 30px 2%;
  }
  .dato_03_tit1xn {
    width: auto;
    padding: 10px;
  }
  .dati_031-list ul li {
    display: inline-block;
    padding-right: 0;
    font-size: 14px;
    line-height: 20px;
  }
  .btn {
    margin-top: 30px;
    background: #ec6526;
    color: #fff;
    padding: 10px 60px;
    font-size: 24px;
    text-align: center;
  }
}
</style>
